.container {
  position: relative;
  height: 100%;
  width: 100%;
}

.bgWrap {
  height: 100%;
  width: 100%;
  z-index: -1;
}

.children {
  position: relative;
  z-index: 1;
}
