/* Banners */
@import 'styles/mixins/clearfix';
@import 'styles/mixins/breakpoints';
@import 'styles/mixins/lazy';
@import 'styles/mixins/buttons';

@import 'styles/config/fonts';
@import 'styles/config/variables';

.banners-group {
  margin-bottom: 1.9rem;
}

.banner {
  display: block;
  position: relative;
  margin-bottom: 2rem;
  // background-color: #ccc;

  > a {
    position: relative;
    display: block;
    outline: none !important;
  }

  img {
    display: block;
    max-width: none;
    // min-height: 20rem;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.banner.banner-menu img {
  height: 100%;
}

.banner-content-container {
  display: flex;
  align-items: center;

  &.align-left {
    justify-content: flex-start;
  }

  &.align-center {
    justify-content: center;
  }
}

.banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8rem 0;
  z-index: 2;

  a {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.banner-big .banner-content.banner-content-center,
.banner-content-center.banner-content {
  max-width: none;
  padding-left: 0;
  right: 0;
  text-align: center;
}

.banner-title {
  color: #333333;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.3;
  margin-bottom: 1.5rem;
  letter-spacing: -0.03em;

  a {
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &.text-white {
    a {
      color: #fff;
    }
  }
}

.banner-subtitle {
  color: #777;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.2;
  letter-spacing: 0;
  margin-bottom: 1rem;

  a {
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &.text-white {
    a {
      color: #fff;
    }
  }
}

.banner-link {
  font-weight: 400;
  display: inline-block;
}

.banner-big {
  color: $body-text;
  font-size: 1.5rem;
  line-height: 1.6;


  .banner-content {
    padding-right: 3rem;
    padding-top: 0;
    max-width: 800px;
    padding-left: 7rem;
  }

  .banner-subtitle {
    margin-bottom: 0.6rem;
    font-weight: 300;
    font-size: 1.4rem;
  }

  .banner-title {
    line-height: 1.15;
    font-weight: 700;
    font-size: 5rem;
    letter-spacing: 0.01em;
    margin-bottom: 1rem;

    + .btn {
      margin-top: 1rem;
    }
  }

  p {
    letter-spacing: 0;
    font-size: 1.3rem;
    line-height: 1.8em;
  }

  p:last-of-type {
    margin-bottom: 3.3rem;
  }
}

@include mq('lg', 'max') {
  .banner-content {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .banner-big {
    .banner-content {
      padding-left: 10rem;
      padding-right: 10rem;
    }

    .banner-title {
      font-size: 5rem;
    }
  }
}

@include mq('md', 'max') {
  .banner-content {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .banner-big {
    .banner-content {
      padding-left: 6rem;
      padding-right: 6rem;
    }

    .banner-title {
      font-size: 4rem;
    }
  }
}

@include mq('sm', 'max') {
  .banner-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .banner-big {
    .banner-content {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    .banner-title {
      font-size: 2.5rem;
    }
  }
}

.banner-display {
  > a {
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      background-color: rgba(51, 51, 51, 0.4);
      transition: all 0.35s ease;
    }
  }

  &:hover {
    > a:before {
      visibility: visible;
      opacity: 1;
    }
  }

  .banner-content {
    padding-top: 0;
  }

  .banner-title {
    font-weight: 600;
    font-size: 2rem;
  }

  &.banner-link-anim {
    .banner-title {
      margin-bottom: 0;
    }

    &:hover {
      .banner-content {
        padding-bottom: 4.5rem;
      }
    }
  }
}

.btn.banner-link {
  font-size: 1.4rem;
  line-height: 1;
  padding: 0.8rem 1.4rem;
  min-width: 0;
  text-transform: uppercase;
  text-decoration: none !important;

  i {
    margin-right: 0;
    margin-left: 0;

    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0.9rem;
    }
  }
}

.banner-overlay {
  > a {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(51, 51, 51, 0.25);
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s ease;
    }
  }

  &.banner-overlay-light {
    > a:after {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  &:hover,
  &:focus {
    > a:after {
      visibility: visible;
      opacity: 1;
    }
  }
}

.banner-border,
.banner-border-hover {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    border: 0.5rem solid rgba(255, 255, 255, 0.3);
    transition: all 0.45s ease;
    pointer-events: none;
  }
}

.banner-border-hover {
  &:after {
    left: 20px;
    right: 20px;
    top: 20px;
    bottom: 20px;
    visibility: hidden;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 0;
  }
}

.banner-border {
  .banner-content {
    padding-left: 60px;
  }

  &:hover:after {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.banner-border-hover:hover {
  &:after {
    visibility: visible;
    opacity: 1;
  }
}

.banner-border {
  .banner-title {
    span {
      font-size: 2.4rem;
      letter-spacing: -0.025em;
    }
  }
  .banner-content {
    padding-left: 7rem;
  }
  .banner-subtitle,
  .banner-title span,
  .banner-link {
    color: #fff;
  }
}
.banner-border-hover .banner-link {
  color: #333;
}

.banner-link.btn {
  padding-left: 0;
  padding-right: 0;
  min-width: none;
  width: fit-content;
  border-radius: 0;
  border: none;
  text-transform: uppercase;
  position: relative;

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -1.8rem;
    font-size: 1.5rem;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: -1.8rem;
    bottom: -0.2rem;
    height: 0.1rem;
    background-color: $primary-color;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }

  &:hover,
  &:focus {
    &::before {
      opacity: 1;
      visibility: visible;
    }
    color: $primary-color;
    background-color: transparent;
  }
}

@include mq('md') {
  .banner-display {
    .banner-title {
      font-size: 2.4rem;
    }
  }
}

@include mq('lg') {
}
